
import { defineComponent, onMounted, watch } from "vue";
import useCurrencyInput from "vue-currency-input";

import { addMoneyMask } from "@/libs/utils";

const CFormInputMoney = defineComponent({
  name: "CFormInputMoney",
  emits: ["update:modelValue"],
  props: {
    elementID: { type: String, required: true },
    placeholder: { type: String, default: "" },
    autocomplete: { type: String, default: "" },
    width: { type: String, default: "100%" },
    marginY: { type: String, default: "0.25rem" },
    marginX: { type: String, default: "0px" },
    marginR: { type: String, default: "" },
    marginL: { type: String, default: "" },
    hasAutofocus: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    modelValue: { required: true, default: null },
  },
  setup(props, { emit }) {
    const fieldWidth = `calc((${props.width} - ${props.marginX} * 2) - (${props.marginR || "0px"} - ${
      props.marginL || "0px"
    }))`;
    const fieldMargin = `${props.marginY} ${props.marginR || props.marginX} ${props.marginY} ${
      props.marginL || props.marginX
    }`;

    const { formattedValue, inputRef, setValue } = useCurrencyInput({
      currency: "BRL",
      locale: "pt-BR",
      useGrouping: true,
      precision: 2,
    });

    watch(
      () => props.modelValue,
      (value: any) => {
        if (inputRef.value) {
          setValue(value || 0);
          formattedValue.value = addMoneyMask(value || 0);
        }
      }
    );

    function handleEmit(event: any) {
      if (event?.detail) emit("update:modelValue", event.detail.number);
    }

    onMounted(() => {
      if (!inputRef.value) return;

      setValue(Number(props.modelValue));
      if (props.hasAutofocus) inputRef.value.focus();
    });

    return { fieldWidth, fieldMargin, formattedValue, inputRef, handleEmit };
  },
});

export default CFormInputMoney;
