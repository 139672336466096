import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "c-form-input-money-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "c-form-input-money-before"
}
const _hoisted_4 = {
  key: 1,
  class: "c-form-input-money-after"
}
const _hoisted_5 = {
  key: 1,
  class: "c-form-input-money-input-before"
}
const _hoisted_6 = ["id", "value", "placeholder", "autofocus", "required", "disabled"]
const _hoisted_7 = {
  key: 2,
  class: "c-form-input-money-input-after"
}
const _hoisted_8 = {
  key: 0,
  class: "c-form-input-money-description"
}
const _hoisted_9 = {
  key: 1,
  class: "c-form-input-money-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['c-form-input-money control', _ctx.elementID, { hasError: _ctx.hasError, isDisabled: _ctx.isDisabled, hasLabel: _ctx.$slots.default }]),
    style: _normalizeStyle({ width: _ctx.fieldWidth, margin: _ctx.fieldMargin })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "c-form-input-money-label",
            for: _ctx.elementID
          }, [
            (_ctx.$slots.before)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "before")
                ]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default"),
            (_ctx.$slots.after)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "after")
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.$slots['input-before'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "input-before")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        ref: "inputRef",
        id: _ctx.elementID,
        value: _ctx.formattedValue,
        class: "c-form-input-money-input input is-primary py-3 px-4",
        placeholder: _ctx.placeholder,
        autofocus: _ctx.hasAutofocus,
        required: _ctx.isRequired,
        disabled: _ctx.isDisabled,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleEmit($event)))
      }, null, 40, _hoisted_6),
      (_ctx.$slots['input-after'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "input-after")
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.hasError.description), 1))
      : (_ctx.$slots.description)
        ? (_openBlock(), _createElementBlock("small", _hoisted_9, [
            _renderSlot(_ctx.$slots, "description")
          ]))
        : _createCommentVNode("", true)
  ], 6))
}